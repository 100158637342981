









































































































import Vue from "vue";
import Component from "vue-class-component";
import WideCardLogoWithTitle from "@/components/shared/WideCardLogoWithTitle.vue";
import { getModule } from "vuex-module-decorators";
import tradeshowVuexModule from "@/store/vuex-modules/getTradeshowData";
import Spinners from "@/components/utilities/Spinners.vue";
import UpcomingStreams from "@/components/exhibitor-profile/UpcomingStreams.vue";
import PageTitleHeader from "@/components/shared/PageTitleHeader.vue";

const tradeshowStore = getModule(tradeshowVuexModule);
// all of this is TODO, so damn solution
@Component({
    components: {
        WideCardLogoWithTitle,
        Spinners,
        PageTitleHeader
    }
})
export default class TradeshowSameLevelTier extends Vue {
    isLoading = true;
    searchString = "";
    beLazy = true;
    filteredData: any[] = [];
    exhibitorData: any[] = [];

    get tradeshowCompanies() {
        const companies = tradeshowStore.allTradeshowCompanies;
        this.filteredData = [];
        this.exhibitorData = [];

        return companies
            .sort((item: any, nxt: any) => {
                return item.companyName.localeCompare(nxt.companyName);
            })
            .filter((item: any) => {
                if (item.level == "4") {
                    this.filteredData.push(item);
                } else {
                    this.exhibitorData.push(item);
                }
            });
    }

    get filterData() {
        if (this.searchString) {
            return this.filteredData.filter((item: any) => {
                if (
                    item.companyDescription
                        .toLowerCase()
                        .includes(this.searchString.toLowerCase()) ||
                    item.companyName
                        .toLowerCase()
                        .includes(this.searchString.toLowerCase()) ||
                    (item.participatingOrganizations &&
                        item.participatingOrganizations
                            .toLowerCase()
                            .includes(this.searchString.toLowerCase()))
                ) {
                    return item;
                }
            });
        }
        return "";
    }

    get exhibitorFilterData() {
        if (this.searchString) {
            return this.exhibitorData.filter((item: any) => {
                if (
                    item.companyDescription
                        .toLowerCase()
                        .includes(this.searchString.toLowerCase()) ||
                    item.companyName
                        .toLowerCase()
                        .includes(this.searchString.toLowerCase())
                ) {
                    return item;
                }
            });
        }
        return "";
    }

    get cssVariables() {
        return this.$store.getters.cssVariables;
    }
    get pageConfig() {
        return this.$store.getters.getPageOptions("tradeshow");
    }

    get pageDecorImage(): string {
        let returnValue = "";
        if (
            this.pageConfig.decorImages &&
            this.pageConfig.decorImages.pageBackground
        ) {
            returnValue = `${process.env.BASE_URL}bg/${this.pageConfig.decorImages.pageBackground}`;
        }
        return returnValue;
    }

    get textHeader() {
        return this.$store.getters.getPageOptions("tradeshow").textHeader;
    }

    created() {
        tradeshowStore.getTradeshowCompanies().finally(() => {
            this.tradeshowCompanies;
            this.isLoading = false;
        });
    }

    searchDescription() {
        //
    }
}
